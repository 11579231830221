
import { Options, Vue } from "vue-property-decorator";
import { pageListUser } from "@/api/safecheck";
@Options({})
export default class extends Vue {
  searchValue = "";
  showPopup = false;
  refreshing = false;
  finished = false;
  loading = false;
  fuzeList = [] as any[];
  page = {
    pageSize: 10,
    pageNum: 1,
  };
  onRefresh() {
    // 清空列表数据
    this.finished = false;

    // 重新加载数据
    // 将 loading 设置为 true，表示处于加载状态
    this.loading = true;
    this.onLoad();
  }
  onLoad() {
    setTimeout(async () => {
      if (this.refreshing) {
        this.fuzeList = [];
        this.page.pageNum = 1;
        this.refreshing = false;
      }
      const res: any = await pageListUser({
        projectId: this.$store.state.project.projectinfo.projectId,
        name: this.searchValue,
        ...this.page,
      });
      if (res.code !== 0) {
        return this.$toast.fail(res.msg);
      }
      this.loading = false;
      this.page.pageNum += 1;
      this.fuzeList = [...this.fuzeList, ...res.data.records];
      if (this.fuzeList.length >= res.data.total) {
        this.finished = true;
      }
      //发送请求
    }, 1000);
  }
  fSearch() {
    this.refreshing = true;
    this.onLoad();
  }
  fSelectPerson(obj: any) {
    (this as any).$parent.form.changePersonId = obj.userId;
    (this as any).$parent.changePerson = obj.name;
    this.showPopup = false;
  }
}
