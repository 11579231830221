
import { Options, Vue } from "vue-property-decorator";
import * as api from "@/api/anquanxunjian";
@Options({})
export default class extends Vue {
  searchValue = "";
  showPopup = false;
  refreshing = false;
  finished = false;
  loading = false;
  canjianList = [] as any[];
  page = {
    pageSize: 10,
    pageNum: 1,
  };
  onRefresh() {
    // 清空列表数据
    this.finished = false;

    // 重新加载数据
    // 将 loading 设置为 true，表示处于加载状态
    this.loading = true;
    this.onLoad();
  }
  onLoad() {
    setTimeout(() => {
      if (this.refreshing) {
        this.canjianList = [];
        this.page.pageNum = 1;
        this.refreshing = false;
      }
      //发送请求
      api
        .listProjectCompany({
          projectId: this.$store.state.project.projectinfo.projectId,
          name: this.searchValue,
        })
        .then((res: any) => {
          this.canjianList = res.data;
          this.loading = false;
          this.finished = true;
          this.refreshing = false;
        });
    }, 1000);
  }
  fSearch() {
    this.page.pageNum = 1;
    this.onRefresh();
  }
  fSelectCompany(item: any) {
    (this as any).$parent.companyName = item.name;
    (this as any).$parent.form.companyId = item.deptId;
    this.showPopup = false;
  }
}
