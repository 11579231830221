
import { Options, Vue } from "vue-property-decorator";
import { projectAreaType } from "@/api/safecheck";
@Options({})
export default class extends Vue {
  searchValue = "";
  showPopup = false;
  refreshing = false;
  finished = false;
  loading = false;
  canjianList = [] as any[];
  page = {
    pageSize: 10,
    pageNum: 1,
  };
  onRefresh() {
    // 清空列表数据
    this.finished = false;

    // 重新加载数据
    // 将 loading 设置为 true，表示处于加载状态
    this.loading = true;
    this.onLoad();
  }
  fSelectCompany(item: any) {
    (this as any).$parent.form.typeId = item.id;
    (this as any).$parent.areaType = item.name;
    this.showPopup = false;
  }
  onLoad() {
    setTimeout(async () => {
      if (this.refreshing) {
        this.canjianList = [];
        this.page.pageNum = 1;
        this.refreshing = false;
      }
      const res: any = await projectAreaType({
        projectId: this.$store.state.project.projectinfo.projectId,
      });
      if (res.code !== 0) {
        return this.$toast.fail(res.msg);
      }
      this.loading = false;
      this.page.pageNum += 1;
      this.canjianList = [...this.canjianList, ...res.data];
      // if (this.canjianList.length >= res.data.total) {
      this.finished = true;
      // }
      //发送请求
    }, 1000);
  }
  fSearch() {
    this.refreshing = true;
    this.onLoad();
  }
}
